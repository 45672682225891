var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "14",
        height: "15",
        viewBox: "0 0 14 15",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M12.8183 1.03635C12.1647 0.376928 11.1244 0.31841 10.4023 0.900451L2.80753 7.02195C2.60671 7.18381 2.44226 7.38686 2.32506 7.61766L1.83864 8.57557C1.03505 10.158 2.71299 11.8511 4.28134 11.0403L5.33615 10.495C5.44419 10.4391 5.54641 10.3724 5.64127 10.296L13.1474 4.24596C13.9929 3.56445 14.0632 2.2925 13.298 1.52039L12.8183 1.03635ZM11.3732 2.12679C11.4764 2.04364 11.625 2.052 11.7183 2.14621L12.198 2.63025C12.3074 2.74055 12.2973 2.92226 12.1765 3.01962L4.6704 9.06965C4.65685 9.08057 4.64225 9.09009 4.62681 9.09807L3.572 9.6434C3.34795 9.75923 3.10825 9.51737 3.22305 9.2913L3.70947 8.33339C3.72621 8.30042 3.74971 8.27141 3.77839 8.24829L11.3732 2.12679ZM0.777778 12.9304C0.348223 12.9304 0 13.2818 0 13.7152C0 14.1486 0.348223 14.5 0.777778 14.5H13.2222C13.6518 14.5 14 14.1486 14 13.7152C14 13.2818 13.6518 12.9304 13.2222 12.9304H0.777778Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }